import React, { useEffect, useState } from "react";
import SideMenu from "../sidemenu";
import "./category.scss";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import { StringFormItem } from "../inputs/StringFormItem";
import { Button, Form, message, Spin, Upload } from "antd";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import { CategoryInterface } from "./InterFace";
import TextareaFormItem from "../inputs/TextareaFormItem";
import { DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import Resizer from "react-image-file-resizer";
// declare var bootstrap: any;
function Menu() {
  // const [category, setCategory] = useState<CategoryInterface | null>(null);
  const [categoryData, setCategoryData] = useState<CategoryInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categoryImg, setCategoryImg] = useState("");
  const [form] = Form.useForm();
  const [showNav, setShowNav] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCatImgModal, setShowCatImgModal] = useState(false);
  // const [showMenuModal, setShowMenuModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [categoryStatus, setCategoryStatus] = useState("");
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    setCategoryId("");
    form.resetFields();
  }
  const handleShow = () => setShowModal(true);
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setCategoryId("");
    form.resetFields();
  }
  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (id: string) => {
    setShowStatusUpdateModal(true)
    setCategoryId(id);
  };
  const handleDeleteShow = (id: string) => {
    setShowDeleteModal(true);
    setCategoryId(id);
  }
  const handleCatImgShow = (id: string) => {
    setShowCatImgModal(true);
    setCategoryImg(id);
  }
  const handleShowCatImgClose = () => {
    setShowCatImgModal(false);
    setCategoryImg("");
    
  }
  useEffect(() => {
    getallCategory();
  }, [form]);
  const prepareEditCategory = async (item: any) => {
    setCategoryId(item._id);
    setLoading(true);
    try {
      await api.get('api/restaurant/menu-category/' + item._id).then(
        function (response) {
          setLoading(false);
          console.log("sucess respp get category");
          console.log(response.data);
          // setCategory(response.data.data);
          console.log("name of cate " + response.data.data.name + " desc of cat " + response.data.data.description);
          form.setFieldsValue({ name: response.data.data.name, description: response.data.data.description });
          handleShow();
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!')
          message.error("Error in API call!")
          setLoading(false);
          console.log(error)
          return Promise.reject(error)
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const addOrUpdateCategory = async () => {

    let url = "";
    const categoryRequestObject = await form.validateFields();
    console.log("add or update category " + JSON.stringify(categoryRequestObject));

    setLoading(true);
    if (categoryId === "") {
      url = 'api/restaurant/menu-category/';
      try {
        await api.post(url, categoryRequestObject).then(
          function (response) {
            console.log("sucess respp");
            message.success(response.data.message)
            setLoading(false);
            toast.success("Category added Successfully!");          
            handleClose();
            handleUploadShow(response.data.data.createdCategoryId)
            getallCategory();
            form.resetFields();
            return response.data
          }
        ).catch(
          function (error) {
            setLoading(false);
            console.log('Show error notification!');
            handleClose();
            message.error("Error in API call!")
            console.log(error)
            return Promise.reject(error)
          }
        );
      } catch (error) {
        console.error("There was an error!", error);
      }
    } else {
      url = 'api/restaurant/menu-category/' + categoryId;
      try {
        await api.put(url, categoryRequestObject).then(
          function (response) {
            message.success(response.data.message)
            console.log("sucess respp");
            setLoading(false);
            toast.success("Category updated Successfully!")
            console.log(response.data);
            setCategoryId("");
            handleClose();
            getallCategory();
            form.setFieldsValue({ name: "", description: "" });
            return response.data
          }
        ).catch(
          function (error) {
            console.log('Show error notification in update!');
            setLoading(false);
            toast.error("Error in api call!");
            console.log(error)
            return Promise.reject(error)
          }
        );
      } catch (error) {
        console.error("There was an error!", error);
      }
    }

  };
  const getallCategory = async () => {
    setLoading(true);
    try {
      await api.get('api/restaurant/menu-category/').then(
        function (response) {
          setLoading(false);
          console.log("sucess respp");
          console.log(response.data);
          setCategoryData(response.data.data);
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!')
          toast.error("Error in api call!")
          setLoading(false);
          console.log(error)
          return Promise.reject(error)
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const deleteCategory = async () => {
    setLoading(true);
    try {
      await api.delete('api/restaurant/menu-category/' + categoryId).then(
        function (response) {
          console.log("sucess respp");
          message.success("deleted Successfully!");
          setLoading(false);
          console.log(response.data);
          handleDeleteClose();
          setCategoryId("");
          getallCategory();
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!');
          message.error(error.response.data.message);
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status ");
    try {
      await api.put('api/restaurant/menu-category/status/' + categoryId + "?statusCode=" + categoryStatus).then(
        function (response) {
          message.success("Status updated Successfully!");
          setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setCategoryId("");
          setCategoryStatus("");
          getallCategory();
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!');
          toast.error("Error in api call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleUploadShow = (itemId: string) => {
    setImageList([]);
    setVideoList([]);
    setUploadImageModal(true);
    setCategoryId(itemId);
  };
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const handleFileChange = async (key, { fileList, file }) => {
    if (key === "images") {
      // Check if the action is "removed" (deletion)
      if (file.status === "removed") {
        setImageList(fileList); // Update imageList with the remaining files after deletion
        return;
      }

      let dimensionErrorShown = false;
      let sizeErrorShown = false;

      // Filter valid images by checking file size and dimensions for new uploads only (those with originFileObj)
      const validImages = await Promise.all(
        fileList
          .filter((file) => file.originFileObj)
          .map((file) => {
            return new Promise((resolve) => {
              // Check file size before checking dimensions
              if (file.size / 1048576 > 1) {
                if (!sizeErrorShown) {
                  message.error("Image size cannot exceed 1 MB.");
                  sizeErrorShown = true; // Prevent duplicate error messages
                }
                resolve(null); // Exclude large image
                return;
              }

              const img = new Image();
              const objectUrl = URL.createObjectURL(file.originFileObj);

              img.onload = () => {
                if (img.width >= 512 && img.height >= 512) {
                  resolve(file);
                } else {
                  if (!dimensionErrorShown) {
                    message.error(
                      "Image dimensions must be at least 512x512 pixels."
                    );
                    dimensionErrorShown = true; // Prevent duplicate dimension messages
                  }
                  resolve(null); // Exclude image with invalid dimensions
                }
                URL.revokeObjectURL(objectUrl); // Clean up object URL
              };

              img.src = objectUrl; // Trigger image load
            });
          })
      );

      // Filter out null values (invalid images due to size or dimensions)
      const filteredImages = validImages.filter((image) => image !== null);

      // Resize images to 512x512 for new uploads only (those with originFileObj)
      const resizedImagesPromises = filteredImages.slice(0, 3).map(
        (file) =>
          new Promise((resolve) => {
            if (file.originFileObj) {
              // Resize only if the image is new (has originFileObj)
              Resizer.imageFileResizer(
                file.originFileObj,
                512, // Width
                512, // Height
                "JPEG", // Format
                100, // Quality
                0, // Rotation
                (uri) => {
                  const blob = dataURLtoBlob(uri);
                  resolve({
                    ...file,
                    originFileObj: new File([blob], file.name, {
                      type: file.type,
                    }),
                  });
                },
                "base64"
              );
            } else {
              // If it's an existing image, just resolve without resizing
              resolve(file);
            }
          })
      );

      const resizedImages = await Promise.all(resizedImagesPromises);

      // Update the imageList with unique images, ensuring no duplicates
      setImageList((prevImageList) => {
        const mergedList = [...prevImageList, ...resizedImages];
        const uniqueImages = mergedList.filter(
          (image, index, self) =>
            index ===
            self.findIndex(
              (t) => t.name === image.name && (!t.url || t.url === image.url)
            )
        );
        return uniqueImages;
      });
    } else if (key === "videos") {
      const validVideo = fileList.filter((file) => file.size / 1048576 <= 10);
      if (validVideo.length !== fileList.length) {
        message.error("Video size cannot exceed 10 MB.");
      }
      setVideoList(validVideo.slice(0, 1));
    }
  };
  const handleRemove = (file) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirmDelete) {
      setImageList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
      message.success("Image deleted successfully.");
    } else {
      // Prevent the removal if canceled
      return false;
    }
  };
  const handleUpload = async () => {
    if (imageList.length === 0 && videoList.length === 0) {
      message.error("Please select at least one image or video.");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();

      // Append new images (files) to 'images' key
      imageList.forEach((image) => {
        if (image.originFileObj) {
          // New images
          formData.append("images", image.originFileObj);
        }
      });

      // Collect all previous image URLs
      const previousImageUrls = imageList
        .filter((image) => !image.originFileObj && image.url) // Only images without originFileObj (old images)
        .map((image) => image.url) // Extract the URLs
        .join(","); // Join URLs with a comma

      // Append the concatenated URLs string to 'imageUrls' key
      if (previousImageUrls) {
        formData.append("imageUrls", previousImageUrls);
      }

      // If you have video handling
      videoList.forEach((video) => {
        formData.append("videos", video.originFileObj);
      });

      // Log image list to check data structure
      console.log("****");
      console.log(imageList);

      // Perform the PUT request

      const response = await fetch(`/api/utility/menu-category/${categoryId}`, {

        method: "PUT",
        headers: {
          authorization: "authorization-text",
        },
        body: formData,
      });

      if (response.ok) {
        message.success("Files uploaded successfully");
        getallCategory();

        handleCloseUpload();
        setImageList([]);
        setVideoList([]);
      } else {
        message.error("File upload failed");

      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    } finally {
      setIsLoading(false);
    }
  };



  const handleCloseUpload = () => {
    setUploadImageModal(false);
    // setDishId(null);
    setImageList([]);
    setVideoList([]);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    {/* <h4>Category List</h4> */}
                    <h4>{SidebarSection.CATEGORY_LIST}</h4>
                  </div>
                  <div className="col text-end">

                    <button
                      type="button"
                      className="btn addbtn"
                      onClick={handleShow}
                    >
                      <i className="bi bi-clipboard-plus "></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">CATEGORY NAME</th>
                          <th scope="col">DESCRIPTION</th>
                          {/* <th scope="col">CODE</th> */}
                          <th scope="col">CATEGORY IMAGE</th>
                          <th scope="col">STATUS</th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryData !== null &&
                          (categoryData as any[]).map((item, index) => (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.description}</td>
                              {/* <td>{item.code}</td> */}

                              <td>
                              {item?.imageAttachement?.[0]?.url ? (
                                <button
                                onClick={() => handleCatImgShow(item.imageAttachement[0].url)}
                                className="btn-action rounded-circle act-btn"
                              >
                                <i className="bi bi-eye"></i>
                              </button>
                              ) : (
                                <span></span>
                              )}
                              </td>

                              <td>
                                <div style={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: "nowrap" }}>
                                  {item.status === "active" && (
                                    <span className="badge bg-success rounded-pill">Active</span>
                                  )}
                                  {item.status === "inactive" && (
                                    <span className="badge bg-danger rounded-pill">Inactive</span>
                                  )}
                                  {item.status === "suspended" && (
                                    <span className="badge bg-warning rounded-pill">Suspended</span>
                                  )}
                                  {item.status === "waiting_for_approval" && (
                                    <span className="badge bg-warning rounded-pill">Waiting for approval</span>
                                  )}

                                  <button
                                    className="btn-action rounded-circle act-btn"
                                    onClick={() => handleStatusUpdateShow(item._id)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </div>
                              </td>

                              <td>
                                <div style={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: "nowrap" }}>
                                  <button
                                    className="btn-action rounded-circle act-btn"
                                    onClick={() => prepareEditCategory(item)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>

                                  {item.status === "active" ? (
                                    <button
                                      className="btn-action rounded-circle act-btn"
                                      onClick={() => {
                                        message.error("Cannot delete: menu category is active.");
                                      }}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => handleDeleteShow(item._id)}
                                      className="btn-action rounded-circle act-btn"
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  )}

                                  <button
                                    className="btn-action rounded-circle act-btn"
                                    onClick={() => handleUploadShow(item._id)}
                                  >
                                    <i className="bi bi-upload"></i>
                                  </button>
                                </div>
                              </td>

                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {showCatImgModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      category Image
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleShowCatImgClose}
                    ></button>
                  </div>
                  <img
                                  src={categoryImg}
                                  alt="Item Image"
                                  width="100%"
                                  height="100%"
                                />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleShowCatImgClose}>
                      Close
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          )}
          {showModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Category Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <Form
                    data-aos-delay={100}
                    data-aos="fade-up"
                    role="form"
                    form={form}
                    layout="vertical"
                    onFinish={addOrUpdateCategory}
                  >
                    <div className="modal-body">
                      <div className="mb-3">
                        <StringFormItem
                          label="Category Name(Ex.Starter/Breakfast/Lunch/Dinner,etc.)"
                          name="name"
                          disabled={""}
                          placeholder="Category name *"
                          rules={[
                            {
                              message: "The input is not valid category!",
                            },
                            {
                              required: true,
                              message: "Please input category!",
                            },
                          ]}
                        />
                      </div>
                      <div className="mb-3">
                        <TextareaFormItem
                          label="Description"
                          name="description"
                          placeholder="Description "

                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}>
                        Close
                      </button>
                      <button type="button"
                        onClick={addOrUpdateCategory} className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )}
          {showDeleteModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Delete category?
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleDeleteClose}
                    ></button>
                  </div>
                  <div className="delete-text"> Are you sure you want to delete?</div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleDeleteClose}>
                      Cancel
                    </button>
                    <button type="button"
                      onClick={deleteCategory} className="btn btn-primary">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {showMenuModal && (
            <div className="modal-content">
              <div className="mb-3">
                <label className="form-label">Menu<span className="text-danger"> *</span></label>
                <div>
                  <select className='form-control down-arrow' name="cars" id="cars">
                    <option value="volvo">Breakfast</option>
                    <option value="saab">Lunch</option>
                    <option value="mercedes">Dinner</option>
                    <option value="audi">Starters</option>
                    <option value="audi">Main Course</option>
                  </select>
                </div>
              </div>
            </div>
          )} */}
          {showStatusUpdateModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Update status?
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleStatusUpdateClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                      <label className="form-label">
                        Select status <span className="text-danger"> *</span>
                      </label>
                      <div>
                        <select className="form-control down-arrow" value={categoryStatus} onChange={(e) => setCategoryStatus(e.target.value)} >
                          <option value="" disabled>Select Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="suspended">Suspended</option>
                        </select>
                      </div>
                    </div></div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleStatusUpdateClose}>
                      Cancel
                    </button>
                    <button type="button"
                      onClick={updateStatus} className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {uploadImageModal && (
            <div
              className="modal"
              tabIndex={-1}
              style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Upload Category image
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseUpload}
                    ></button>
                  </div>
                  <section className="main shadow-sm">
                    {isLoading ? (
                      <div className="text-center my-4">
                        <Spin tip="Uploading... Please wait" />
                      </div>
                    ) : (
                      <>
                        <div className="row ">
                          <div className="col-lg-6 col-md-6 text-center">
                            <Upload
                              multiple
                              listType="picture-card" // Display images as thumbnails
                              beforeUpload={() => false} // Disable auto upload
                              fileList={imageList} // Use the imageList state
                              onChange={(info) => handleFileChange("images", info)} // Handle file change
                              onRemove={handleRemove} // Handle remove action
                            >
                              <Button
                                className="text-start"
                                style={{ width: "310px", height: "103px" }}
                                icon={<UploadOutlined />}
                              >
                                <b>Upload</b>
                                <span className="text-danger"> *</span>
                              </Button>
                            </Upload>
                            <span>
                              {" "}
                              (*dimensions should be 512*512 max.)
                            </span>

                          </div>
                          {/* <div className="col-lg-6 col-md-6 text-center">
                        <Upload
                          multiple
                          beforeUpload={() => false}
                          fileList={videoList}
                          onChange={(info) => handleFileChange("videos", info)}
                        >

                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                          >
                            Dish video
                          </Button>
                          <span> (*Please select only one video)</span>
                        </Upload>
                      </div> */}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={handleCloseUpload}
                          >
                            Close
                          </button>
                          <button
                            onClick={handleUpload}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Save
                          </button>
                          <ToastContainer />
                        </div>
                      </>
                    )}
                  </section>
                </div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      )};
    </>
  );
}

export default Menu;
