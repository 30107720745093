import { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { message } from "antd";
interface ChildComponentProps {
  handleRegisterClose: () => void;
  SetMenuID: (id: string) => void;
  SetPayloaddata: (formData: any) => void;
}
const DishRegister = (props: ChildComponentProps) => {
  const { handleRegisterClose, SetMenuID, SetPayloaddata } = props;
  const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [selectedDish, setSelectedDish] = useState([]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [errors, setErrors] = useState<{ name?: string; description?: string;  totalPrice?: string }>({});
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    totalPrice: 0,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Allow any input for name and description
    const allowAnyInput = /[^]+/;

    if (
      name === "name" ||
      name === "description" ||
      allowAnyInput.test(value) ||
      value === ""
    ) {
      setFormData({
        ...formData,
        [name]:

          name === "totalPrice"
            ? parseFloat(value) || 0 // Set value to 0 if it's NaN
            : value,
      });
    } else {
      // Handle the case when the input is not a valid input for name or description
      setFormData({
        ...formData,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    const postData = {
      name: formData.name,
      description: formData.description,
      totalPrice: formData.totalPrice,
    };

    try {
      const response = await api.post("/api/restaurant/event-menu/", postData);

      // console.log("this dish response", response);
      setFormData({
        name: "",
        description: "",
        totalPrice: null,

      });

      message.success(response.data.message);
      SetPayloaddata(formData);
      handleRegisterClose();
      SetMenuID(response.data.data._id)

    } catch (error: any) {
      // Explicitly type 'error' as 'any'
      console.error("There was an error!", error);

      if (error.response) {
        // If the error has a response, extract the error message from the response data
        toast.error(error.response.data.message);
      } else {
        // If there is no response, display a generic error message
        toast.error("Please fill out all required fields.");
      }
    }
  };
  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, []);
  
  
  const validate = (): boolean => {
    const newErrors: { name?: string; description?: string; totalPrice?: string } = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name required.";
    
    }
    // if (!formData.description.trim()) {
    //   newErrors.description = "description required";
    // }
    // if (!formData.totalPrice) {
    //   newErrors.totalPrice = "totalPrice required";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  return (
    <>
      <div
        className="modal"
        tabIndex={-1}
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Special Event Meal

              </h1>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleRegisterClose}
              ></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        Name Of The Meal<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        placeholder="Enter Name Of The Meal"
                        onChange={handleInputChange}
                      />
                      {errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        {" "}
                        Description{" "}
                        {/* <span className="text-danger"> *</span> */}
                      </label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        rows={2}
                        placeholder="Enter description about the Event"
                      ></textarea>
                      {/* {errors.description && <div className="text-danger">{errors.description}</div>} */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        {" "}
                        {/* Base Production Cost(INR) */}
                        Total Price ({defaultCurrency})
                        {/* <span className="text-danger"> *</span>  */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="totalPrice"
                        value={formData.totalPrice}
                        placeholder="Enter Base Production Cost"
                        onChange={handleInputChange}
                      />
                      {/* {errors.totalPrice && <div className="text-danger">{errors.totalPrice}</div>} */}
                    </div>
                  </div>
                </div>



              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleRegisterClose}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save & Next <i className="bi bi-caret-right-fill"></i>
                </button>
                <ToastContainer />
              </div>
            </form>

          </div>
        </div>
      </div>


    </>
  );
};

export default DishRegister;
