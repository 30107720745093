import React, { useEffect, useState } from "react";
// import { RestaurantSection } from "../../../constants/constant";
// Using ESM specification
import "./restaurantMenu.css";
// Using a bundler like webpack
import GLightbox from "glightbox";
import { toast } from "react-toastify";
import api from "../../../api";
import defaultImg from "./img/menu/no img.png";
// import { useParams } from "react-router-dom";
import { message } from "antd";
import Loader from "../../Loader";
// Define the type for props
interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;
    selectedCurrency: string;
  };
}
interface RestaurantBranchMenuDetailInterface {
  _id: string;
  name: string;
  isActive: boolean;
  description: string;
  totalPrice: number;
  offerPrice: number;
  dish :{
    _id:string;
    name:string;
    imageAttachement: any[];
    videoAttachement: any[];
  }
  
  
}
export default function DiscountedOffer({ data }: RestaurantMenuProps) {
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);

  const [selectedCurrencyData, setSelectedCurrencyData] = useState(String);
  const [loading, setLoading] = useState(true);

  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface>();

  // State to manage active tab
  // const [activeTab, setActiveTab] = useState(restaurantBrantMenuDetails?.category[0]._id);

  // Function to handle tab change
  // const handleTabChange = (categoryId: string) => {
  //   setActiveTab(categoryId);
  // };

  useEffect(() => {
    console.log(
      "inside RestaurantMenu component menuId: " +
        data.menuId +
        " branchId: " +
        data.branchId +
        " restaurantId: " +
        data.restaurantId
    );
    setSelectedCurrencyData(data.selectedCurrency)
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }
  }, [data.menuId, data.branchId, data.restaurantId]);

  const getRestaurantBranchMenuDetails = async () => {
    setLoading(true);
    try {
      var url =
        "/api/distributor/branch-menu/" +
        data.menuId +
        "?branchId=" +
        data.branchId +
        "&restaurantId=" +
        data.restaurantId +
        "&isPreview=true";
      const response = await api.get(url);
      console.log("success menu details response", response.data.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setLoading(false);

      // let currencyData = localStorage.getItem("currencyDetails");
      // setSelectedCurrencyData(currencyData);
      // console.log("currencyData" + currencyData);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response.data.message);
    }
  };
  

  useEffect(() => {
    // Initialize GLightbox when the component mounts
    const lightbox = GLightbox({
      selector: ".glightbox", // selector for lightbox elements
    });

    // Optionally, you can clean up the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []); // empty dependency array ensures the effect runs only once

  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };
  
  const viewDetails = (dish: any) => {
    console.log(JSON.stringify(dish));
    setShowViewBranchModal(true);
    
  }

  return (
    <>
      {/* ======= Menu Section ======= */}
      <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
        {loading ? (
            <Loader />
          ) : (
            <>
            <div className="row gy-4">
          
            <div className="">
              <div className="custom-dish-card h-100" style={{ height: '400px' }} onClick={() => viewDetails(restaurantBrantMenuDetails.dish)}>
                <div className="cursor-pointer" >
                  <img 
                    src={
                      restaurantBrantMenuDetails?.dish.imageAttachement?.[0]?.url ||
                      defaultImg
                    }
                    className="card-img"
                    alt={restaurantBrantMenuDetails?.dish.name || "Default Alt Text"}
                     
                  />
                  <div className="offer-price-overlay">
                {restaurantBrantMenuDetails?.totalPrice > 0 && 
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#FF0000",
                      marginRight: "8px",
                    }}
                  >
                    {selectedCurrencyData}{" "}
                    {restaurantBrantMenuDetails?.totalPrice}
                  </span>
                }

                
                <span style={{ color: "black" }}>{selectedCurrencyData}{" "}
                {restaurantBrantMenuDetails?.offerPrice ?? 0}</span>
                </div>
                </div>
                <div className="custom-dish-card-body">
                  <h5 className="custom-dish-card-title dish-name cursor-pointer">{restaurantBrantMenuDetails?.name}</h5>                              
                  <p className="custom-dish-card-text text-muted">
  {restaurantBrantMenuDetails?.description 
    ? `${restaurantBrantMenuDetails.description.substring(0, 25)}...` 
    : <><br /></>}
</p>

                  
                  
                </div>
              </div>
            </div>
         
              </div></>
          
          )}
          {showViewBranchModal && (
            <div
              className="modal modal-lg"
              tabIndex={-1}
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Flash Dealz Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleViewBranchClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                    <table className="table table-bordered view-table infoTable">
                        <tbody>
                          <tr>
                            <th>Dish Name</th>
                            <td>{restaurantBrantMenuDetails?.dish.name}</td>
                            <th>Description</th>
                            <td>{restaurantBrantMenuDetails?.description}</td>
                          </tr>
                          <tr>
                            <th>Original Price</th>
                            <td>{selectedCurrencyData} {restaurantBrantMenuDetails?.totalPrice}</td>
                            <th>Offer Price</th>
                            <td>{selectedCurrencyData} {restaurantBrantMenuDetails?.offerPrice}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                          {restaurantBrantMenuDetails?.dish.imageAttachement
                            .concat(restaurantBrantMenuDetails?.dish.videoAttachement)
                            .map((media, index) => {
                              const isImage = media.url.match(
                                /\.(jpeg|jpg|gif|png|jfif)$/i
                              );
                              const isVideo =
                                media.url.match(/\.(mp4|webm|ogg)$/i);

                              return (
                                <div
                                  key={media._id}
                                  className={`carousel-item ${index === 0 ? "active" : ""
                                    }`}
                                >
                                  {isImage && (
                                    <img
                                      src={media.url}
                                      className="d-block w-100"
                                      alt={`Slide ${index}`}
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  {isVideo && (
                                    <video
                                      className="d-block w-100"
                                      width={250}
                                      height={310}
                                      autoPlay
                                      loop
                                      muted
                                    >
                                      <source
                                        src={media.url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <br />
                        
                        {/* Show navigation buttons only if there's more than one media item */}
                        {restaurantBrantMenuDetails?.dish.imageAttachement.length +
                          restaurantBrantMenuDetails?.dish.videoAttachement.length >
                          1 && (
                            <>
                              <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                              >
                                <span
                                  className="carousel-control-prev-icon"
                                  style={{ backgroundColor: "black" }}
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="next"
                              >
                                <span
                                  className="carousel-control-next-icon"
                                  style={{ backgroundColor: "black" }}
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </>
                          )}
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
