import React, { useEffect, useRef, useState } from "react";
import "./restaurantMenu.css";
import GLightbox from "glightbox";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../Loader";
import defaultImg from "./img/menu/no img.png";
import defaultcatImg from "./img/menu/menu-item-3.png";
import {
  Dish,
  RestaurantBranchMenuDetailInterface,
} from "./RestaurantBranchMenuDetailInterface";
import { message } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Define the type for props
interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;
    selectedCurrency: string;
  };
}

export default function DefaultMenu({ data }: RestaurantMenuProps) {
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [selectedCurrencyData, setSelectedCurrencyData] = useState<string>(
    data.selectedCurrency
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDishes, setFilteredDishes] = useState<Dish[]>([]);
  const [categoryFilters, setCategoryFilters] = useState({});
  const tabRefs = useRef({});
  const [showDropdown, setShowDropdown] = useState(false);

  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface>();
  const [loading, setLoading] = useState(true);

  // State to manage active tab
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  // Define categoryRefs with proper typing
  const categoryRefs = useRef<{
    [key: string]: React.RefObject<HTMLDivElement>;
  }>({});


  const navRef = useRef<HTMLUListElement>(null);
  const [dropdownStates, setDropdownStates] = useState({});

  const toggleDropdown = (categoryId) => {
    setDropdownStates((prevState) => {
      // If clicking the same category that's already open, close it
      if (prevState[categoryId]) {
        return {};
      }
      
      // If clicking a different category, close all others and open this one
      return {
        [categoryId]: true
      };
    });
  };

  const closeDropdowns = () => {
    setDropdownStates({});
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(categoryRefs.current).every(
        (id) =>
          categoryRefs.current[id].current &&
          !categoryRefs.current[id].current.contains(event.target)
      );
      if (isOutside) {
        closeDropdowns();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Initialize categoryRefs with refs for each category
    if (restaurantBrantMenuDetails) {
      restaurantBrantMenuDetails.category.forEach((item) => {
        if (!categoryRefs.current[item._id]) {
          categoryRefs.current[item._id] = React.createRef();
        }
      });
    }
  }, [restaurantBrantMenuDetails]);



  // Scroll left function
  const scrollLeft = () => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  // Scroll right function
  const scrollRight = () => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  const scrollMenuContainerRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const scrollMenuContainer = scrollMenuContainerRef.current;
    const searchBarElement = document.querySelector('.search-bar');
    
    const handleScroll = () => {
      if (!scrollMenuContainer || !searchBarElement) return;
  
      const searchBarBottom = searchBarElement.getBoundingClientRect().bottom;
      const headerOffset = 64; // Your header height
      
      // Make sticky only when scrolled past the search bar
      const shouldBeSticky = searchBarBottom <= headerOffset;
      setIsSticky(shouldBeSticky);
  
      // Handle active category detection
      if (!restaurantBrantMenuDetails?.category) return;
  
      const categoryElements = restaurantBrantMenuDetails.category.map(cat => ({
        id: cat._id,
        element: categoryRefs.current[cat._id]?.current
      })).filter(item => item.element);
  
      // Adjust the scroll position check to account for the header offset
      const scrollPosition = window.scrollY + 260; // Match the headerOffset from handleTabChange
      
      // Find the last category that has been scrolled past
      let activeCategory = null;
      for (const category of categoryElements) {
        if (!category.element) continue;
        
        const elementTop = category.element.offsetTop;
        if (scrollPosition >= elementTop) {
          activeCategory = category.id;
        }
      }
  
      if (activeCategory && activeCategory !== activeTab) {
        setActiveTab(activeCategory);
        
        const activeTabElement = tabRefs.current[activeCategory];
        if (activeTabElement && navRef.current) {
          const tabRect = activeTabElement.getBoundingClientRect();
          const navRect = navRef.current.getBoundingClientRect();
          
          if (tabRect.left < navRect.left || tabRect.right > navRect.right) {
            activeTabElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center'
            });
          }
        }
      }
    };
  
    // Throttle scroll handler
    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };
  
    window.addEventListener('scroll', scrollHandler);
    handleScroll(); // Initial check
  
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [restaurantBrantMenuDetails?.category, activeTab]);

  const handleTabChange = (categoryId: string) => {
    setActiveTab(categoryId);
  
    const targetCategory = categoryRefs.current[categoryId]?.current;
    if (targetCategory) {
      const headerOffset = 260; // Adjusted offset for better positioning
      const elementPosition = targetCategory.offsetTop;
      const offsetPosition = elementPosition - headerOffset + 50; // Add small offset to ensure correct highlighting
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const viewDetails = (dish: Dish) => {
    console.log(JSON.stringify(dish));
    setShowViewBranchModal(true);
    setSelectedDish(dish);
  };

  useEffect(() => {
    console.log(
      "inside RestaurantMenu component menuId: " +
      data.menuId +
      " branchId: " +
      data.branchId +
      " restaurantId: " +
      data.restaurantId
    );
    setSelectedCurrencyData(data.selectedCurrency);
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }
  }, [data.menuId, data.branchId, data.restaurantId]);

  const getRestaurantBranchMenuDetails = async () => {
    setLoading(true);
    try {
      const url = `/api/distributor/branch-menu/${data.menuId}?branchId=${data.branchId}&restaurantId=${data.restaurantId}&isPreview=true`;
      const response = await api.get(url);
      console.log("success menu details response", response.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response?.data?.message || "An error occurred.");
      setLoading(false);
    }
  };

  useEffect(() => {
    // Ensure that restaurantBrantMenuDetails has been updated before setting the active tab
    if (
      restaurantBrantMenuDetails &&
      restaurantBrantMenuDetails.category.length > 0
    ) {
      // handleTabChange(restaurantBrantMenuDetails.category[0]._id);
    }
  }, [restaurantBrantMenuDetails]);

  useEffect(() => {
    // Initialize GLightbox when the component mounts
    const lightbox = GLightbox({
      selector: ".glightbox", // selector for lightbox elements
    });

    // Clean up the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredDishes([]);
      return;
    }

    // Filter dishes across all categories
    const filtered = restaurantBrantMenuDetails?.category.flatMap((cat) =>
      cat.dish?.filter((dish) => dish.name.toLowerCase().includes(value))
    );

    setFilteredDishes(filtered || []);
  };

  const applyFilters = (dishes, filters) => {
    let result = [...dishes];
  
    // Filter by veg/non-veg
    if (filters.veg) {
      result = result.filter(dish => dish.type === "veg");
    }
    if (filters.nonVeg) {
      result = result.filter(dish => dish.type === "non-veg");
    }
  
    // Sort by price
    if (filters.priceSort === 'asc') {
      result.sort((a, b) => a.price - b.price);
    } else if (filters.priceSort === 'desc') {
      result.sort((a, b) => b.price - a.price);
    }
  
    return result;
  };
  
  const handleFilterChange = (categoryId, filterType, value) => {
    setCategoryFilters(prev => {
      const currentFilters = prev[categoryId] || {
        veg: false,
        nonVeg: false,
        priceSort: null
      };
      
      const newFilters = { ...currentFilters };
      
      switch (filterType) {
        case 'veg':
          newFilters.veg = value;
          newFilters.nonVeg = false;
          break;
        case 'nonVeg':
          newFilters.nonVeg = value;
          newFilters.veg = false;
          break;
        case 'price':
          newFilters.priceSort = value;
          break;
      }
      
      return {
        ...prev,
        [categoryId]: newFilters
      };
    });
  };
  const clearFilters = (categoryId) => {
    setCategoryFilters(prev => ({
      ...prev,
      [categoryId]: {
        veg: false,
        nonVeg: false,
        priceSort: null
      }
    }));
  };

  return (
    <>
      <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
          {loading ? (
            <Loader />
          ) : (
            <>
              {/* <div className="section-header">
                <h2 className="menu_name">
                  {restaurantBrantMenuDetails?.name}
                </h2>
                <h2>
                  <span>{restaurantBrantMenuDetails?.description}</span>
                </h2>
              </div> */}
              {/* Search Bar */}
              <div className="search-bar mb-4 d-flex align-items-center">
                <i className="bi bi-search me-2" style={{ fontSize: 'xx-large' }}></i>
                <input
                  type="text"
                  className="form-control regular-menu-search"
                  style={{ width: '300px' }}
                  placeholder="Search for dishes..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>


              <div 
                ref={scrollMenuContainerRef}
                className={`scroll-menu-container ${isSticky ? 'sticky' : ''}`}
              >
                {/* Left Scroll Button */}
                <button
                  className="scroll-btn left left-scroll-btn"
                  onClick={scrollLeft}
                >
                  <FaChevronLeft size={20} />
                </button>

                {/* Scrollable Menu */}
                <div className="scroll-menu-wrapper">
                  <ul
                    className="nav nav-tabs scroll-menu"
                    ref={navRef}
                  >
                    {restaurantBrantMenuDetails?.category?.map((item, index) => (
                      <li 
                      key={index} 
                      className="nav-item catimg"
                      ref={(el) => tabRefs.current[item._id] = el}
                    >
                      <a
                        className={`nav-link ${activeTab === item._id ? 'active custom-active' : ''}`}
                        onClick={() => handleTabChange(item._id)}
                      >
                        <img
                          src={item.imageAttachement[0]?.url || defaultcatImg}
                          alt={item.name}
                          style={{ width: "70px", height: "70px", borderRadius: "50%" }}
                        />
                        <p className={activeTab === item._id ? "catactivetab custom-catactivetab" : ""}>
                          {item.name}
                        </p>
                      </a>
                    </li>
                    ))}
                  </ul>
                </div>

                {/* Right Scroll Button */}
                <button
                  className="scroll-btn right right-scroll-btn"
                  onClick={scrollRight}
                >
                  <FaChevronRight size={20} />
                </button>
              </div>

              {/* Display categories with their dishes */}
              {!searchTerm && restaurantBrantMenuDetails?.category?.map((category, index) => (
                <div
                  key={index}
                  id={category._id}
                  ref={categoryRefs.current[category._id]}
                  className="category-section"
                >
                  {/* Category Heading */}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>
                      <span
                        style={{
                          textTransform: "capitalize",
                          display: "inline-block",
                        }}
                      >
                        {category.name}
                      </span>
                      <h5
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          color: "gray",
                        }}
                      >
                        ({category.dish ? category.dish.length : 0} items)
                      </h5>
                    </h4>


                    <div style={{ position: "relative" }}>
                      <button
                        className="btn btn-light"
                        style={{ border: "none", background: "transparent" }}
                        onClick={() => toggleDropdown(category._id)}
                      >
                        <i
                          className="bi bi-sliders2-vertical"
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </button>

                      
                      {dropdownStates[category._id] && (
                        <div
                          className="dropdown-menu"
                          style={{
                            display: "block",
                            position: "absolute",
                            right: 0,
                            top: "2.5rem",
                            zIndex: 100,
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            padding: "15px",
                            backgroundColor: "white",
                            minWidth: "200px"
                          }}
                        >
                          {/* Price Filter Section */}
                          <div className="filter-section">
                            <h6 className="filter-heading mb-2">Sort by Price</h6>
                            <div className="dropdown-filter" style={{ padding: "5px 10px" }}>
                              <input
                                type="radio"
                                id={`lowToHigh-${category._id}`}
                                name={`price-${category._id}`}
                                value="lowToHigh"
                                checked={categoryFilters[category._id]?.priceSort === 'asc'}
                                onChange={(e) => handleFilterChange(category._id, 'price', 'asc')}
                              />
                              <label htmlFor={`lowToHigh-${category._id}`} style={{ marginLeft: "8px" }}>
                                Low to High
                              </label>
                            </div>
                            <div className="dropdown-filter" style={{ padding: "5px 10px" }}>
                              <input
                                type="radio"
                                id={`highToLow-${category._id}`}
                                name={`price-${category._id}`}
                                value="highToLow"
                                checked={categoryFilters[category._id]?.priceSort === 'desc'}
                                onChange={(e) => handleFilterChange(category._id, 'price', 'desc')}
                              />
                              <label htmlFor={`highToLow-${category._id}`} style={{ marginLeft: "8px" }}>
                                High to Low
                              </label>
                            </div>
                          </div>
                          
                          <hr style={{ margin: "15px 0", borderTop: "2px solid #eee" }} />
                      
                          {/* Dietary Filter Section */}
                          <div className="filter-section">
                            <h6 className="filter-heading mb-2">Dietary Preference</h6>
                            <div
                              className="dropdown-filter"
                              style={{
                                padding: "5px 10px",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <input 
                                type="checkbox" 
                                id={`veg-${category._id}`}
                                checked={categoryFilters[category._id]?.veg}
                                onChange={(e) => handleFilterChange(category._id, 'veg', e.target.checked)}
                              />
                              <label
                                htmlFor={`veg-${category._id}`}
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                Veg
                                <div className="veg-icon" style={{ marginLeft: "8px" }}>
                                  <div className="circle"></div>
                                </div>
                              </label>
                            </div>
                            <div
                              className="dropdown-filter"
                              style={{
                                padding: "5px 10px",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <input
                                type="checkbox"
                                id={`nonVeg-${category._id}`}
                                checked={categoryFilters[category._id]?.nonVeg}
                                onChange={(e) => handleFilterChange(category._id, 'nonVeg', e.target.checked)}
                              />
                              <label
                                htmlFor={`nonVeg-${category._id}`}
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                Non-Veg
                                <div className="nonveg-icon" style={{ marginLeft: "8px" }}>
                                  <div className="triangle"></div>
                                </div>
                              </label>
                            </div>
                          </div>
                          {(categoryFilters[category._id]?.veg || categoryFilters[category._id]?.nonVeg || categoryFilters[category._id]?.priceSort) && (
                            <>
                              <hr style={{ margin: "15px 0", borderTop: "2px solid #eee" }} />
                              <div className="filter-section">
                                <button
                                  className="btn btn-outline-secondary btn-sm w-100"
                                  onClick={() => clearFilters(category._id)}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    borderRadius: "4px"
                                  }}
                                >
                                  Clear All Filters
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <p>{category.description}</p> */}

                  {/* Dishes under the category */}
                  <div className="row gy-4">
                  {category.dish && applyFilters(category.dish, categoryFilters[category._id] || {}).map((dish, dishIndex) => (
                      <div
                        key={dishIndex}
                        className="col-lg-3 col-md-4 col-sm-6"
                      >
                        <div
                          className="custom-dish-card h-100"
                          onClick={() => viewDetails(dish)}
                        >
                          <div className="cursor-pointer">
                            <img
                              src={dish?.imageAttachement[0]?.url || defaultImg}
                              className="card-img"
                              alt={dish.name}
                            />
                            {/* <div className="offer-price-overlay">               
                            <span style={{ color: "black" }}>
                            {selectedCurrencyData} {dish.price}</span>
                          </div> */}
                          </div>
                          <div className="unique-dish-card-body">

                            {dish.type === "veg" && (
                              <div className="dish-veg-icon" style={{ marginLeft: "8px" }}>
                                <div className="circle"></div>
                              </div>
                            )}
                            {dish.type === "non-veg" && (
                              <div className="dish-nonveg-icon" style={{ marginLeft: "8px" }}>
                                <div className="triangle"></div>
                              </div>
                            )}
                            {/* Add Veg Icon */}
                            <p className="unique-dish-card-title">
                              {dish.name.length > 25
                                ? `${dish.name.substring(0, 25)}...`
                                : dish.name}{" "}
                            </p>
                            <p className="unique-dish-price">
                              {selectedCurrencyData} {dish.price}
                            </p>
                            <p className="unique-dish-details">
                              {dish.preparationTime > 0 && ` ${dish.preparationTime} min`}

                              {dish.servingSize > 0 && `• Good for ${dish.servingSize}`}
                            </p>
                            <p className="unique-dish-description">
                              {dish.description.length > 40
                                ? `${dish.description.substring(0, 40)}...`
                                : dish.description}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {/* Display Search Results */}
              {searchTerm && filteredDishes.length > 0 && (
                <div>
                  <h3 className="mb-3">Search Results</h3>
                  <div className="row gy-4">
                    {filteredDishes.map((dish, dishIndex) => (
                      <div
                        key={dishIndex}
                        className="col-lg-3 col-md-4 col-sm-6"
                      >
                        <div
                          className="custom-dish-card h-100"
                          onClick={() => viewDetails(dish)}
                        >
                          <div className="cursor-pointer">
                            <img
                              src={dish?.imageAttachement[0]?.url || defaultImg}
                              className="card-img"
                              alt={dish.name}
                            />
                          </div>
                          <div className="unique-dish-card-body">
                            {dish.type === "veg" && (
                              <div className="dish-veg-icon" style={{ marginLeft: "8px" }}>
                                <div className="circle"></div>
                              </div>
                            )}
                            {dish.type === "non-veg" && (
                              <div className="dish-nonveg-icon" style={{ marginLeft: "8px" }}>
                                <div className="triangle"></div>
                              </div>
                            )}
                            <p className="unique-dish-card-title">
                              {dish.name}
                            </p>
                            <p className="unique-dish-price">
                              {data.selectedCurrency} {dish.price}
                            </p>
                            <p className="unique-dish-details">
                              {dish.preparationTime > 0 && ` ${dish.preparationTime} min`}
                              {dish.servingSize > 0 && `• Good for ${dish.servingSize}`}
                            </p>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* No Search Results */}
              {searchTerm && filteredDishes.length === 0 && (
                <p>No dishes found for "{searchTerm}".</p>
              )}
            </>
          )}

          {showViewBranchModal && (
            <div
              className="modal modal-lg"
              tabIndex={-1}
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Dish Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleViewBranchClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                      <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                          {selectedDish.imageAttachement
                            .concat(selectedDish.videoAttachement)
                            .map((media, index) => {
                              const isImage = media.url.match(
                                /\.(jpeg|jpg|gif|png|jfif)$/i
                              );
                              const isVideo =
                                media.url.match(/\.(mp4|webm|ogg)$/i);

                              return (
                                <div
                                  key={media._id}
                                  className={`carousel-item ${index === 0 ? "active" : ""
                                    }`}
                                >
                                  {isImage && (
                                    <img
                                      src={media.url}
                                      className="d-block w-100"
                                      alt={`Slide ${index}`}
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  {isVideo && (
                                    <video
                                      className="d-block w-100"
                                      width={250}
                                      height={310}
                                      autoPlay
                                      loop
                                      muted
                                    >
                                      <source
                                        src={media.url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <br />
                        {/* Show navigation buttons only if there's more than one media item */}
                        {selectedDish.imageAttachement.length +
                          selectedDish.videoAttachement.length >
                          1 && (
                            <>
                              <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                              >
                                <span
                                  className="carousel-control-prev-icon"
                                  style={{ backgroundColor: "black" }}
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="next"
                              >
                                <span
                                  className="carousel-control-next-icon"
                                  style={{ backgroundColor: "black" }}
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </>
                          )}
                      </div>

                      <table className="table table-bordered dish-view-table custom-width-Dishtable">
                        <tbody>
                          <tr>
                            <th style={{ borderColor: "#aaaaaa" }}>
                              Dish Name
                            </th>
                            <td style={{ borderColor: "#aaaaaa" }}>
                              {selectedDish.name}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ borderColor: "#aaaaaa" }}>
                              Dish Details{" "}
                            </th>
                            <td style={{ borderColor: "#aaaaaa" }}>
                              {selectedDish.description}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ borderColor: "#aaaaaa" }}>Price</th>
                            <td style={{ borderColor: "#aaaaaa" }}>
                              {selectedCurrencyData} {selectedDish.price}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ borderColor: "#aaaaaa" }}>
                              Ingredient
                            </th>
                            <td style={{ borderColor: "#aaaaaa" }}>
                              {selectedDish.ingrediants
                                .map((ingredient) => ingredient.name)
                                .join(", ")}
                            </td>
                          </tr>
                          {selectedDish.preparationTime > 0 &&
                            <tr>
                              <th style={{ borderColor: "#aaaaaa" }}>
                                Preparation Time(Minutes)
                              </th>
                              <td style={{ borderColor: "#aaaaaa" }}>
                                {selectedDish.preparationTime}
                              </td>
                            </tr>}
                          <tr>
                            {selectedDish.servingSize > 0 &&
                              <><th
                                style={{
                                  borderColor: "#aaaaaa",
                                  background: "white",
                                }}
                              >
                                Good For
                              </th>
                                <td style={{ borderColor: "#aaaaaa" }}>
                                  {selectedDish.servingSize} Person
                                </td></>
                            }
                          </tr>
                        </tbody>
                      </table>
                      <p className="text-danger">
                        * Please inform the staff if you are allergic to any
                        ingredients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
