import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./footer.css";
// import { RestaurantDetailInterface } from "../restaurant-list/interFace";
import api from "../../api";
import { BranchDetailsInterface } from "./BranchDetailsInterface";
import { branchDetailInterface } from "../restaurant-list/interFace";
export default function Footer() {
  const { pathname } = useLocation();
  const [isShowWorkingHours, setIsShowWorkingHours] = useState(false);
  console.log("path is " + pathname);  
  const [branchDetail, setBranchDetail] = useState<branchDetailInterface>();


  useEffect(() => {
    if (pathname.startsWith("/restaurant")) {
      setIsShowWorkingHours(true);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const branchId = params.get("b");
      const restaurantId = params.get("c");
      if (restaurantId && branchId) {
        getBranchAllDetails(restaurantId, branchId);
      }
    } else {
      setIsShowWorkingHours(false);
    }
  }, [pathname]);

  const formatTime = (time: string): string => {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    
    if (hour === 0) return `12:${minutes} AM`;
    if (hour === 12) return `12:${minutes} PM`;
    if (hour > 12) return `${hour - 12}:${minutes} PM`;
    return `${hour}:${minutes} AM`;
  };

  const getBranchAllDetails = async (restaurantId: string, branchId: string) => {
    let menuIdBlank = "{menuId}";
    try {
      await api
        .get(
          "/api/distributor/branch-menu/" +
            menuIdBlank +
            "?branchId=" +
            branchId +
            "&restaurantId=" +
            restaurantId +
            "&isPreview=true"
        )
        .then(function (response) {
          
          setBranchDetail(response.data.data.branch);
          console.log("in futtor==>" , response.data.data.branch);
        })
        .catch(function (error) {
          console.log("Show error notification!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  
  if (
    pathname === "/sidebar" ||
    pathname === "/menu" ||
    pathname === "/dish" ||
    pathname === "/restaurants" ||
    pathname === "/profile" ||
    pathname === "/theme" ||
    pathname === "/admin-panel" ||
    pathname === "/restaurant-theme" ||
    pathname === "/category" ||
    pathname === "/branch-menu" ||
    pathname === "/branches" ||
    pathname === "/menu-creation" ||
    pathname === "/menu-view" ||
    pathname === "/restaurants-info" ||
    pathname === "/document-upload" ||
    pathname === "/menu-creation" ||
    pathname === "/today-combo-offers" ||
    pathname === "/today-discounted-offers" ||
    pathname === "/upcoming-offers" ||
    pathname === "/restaurant-special" ||
    pathname === "/todays-special" ||
    pathname === "/event-meal" ||
    pathname === "/buffet-meal" ||
    pathname === "/users" ||
    // pathname === "/home-page" ||
    pathname === "/subscription-plan" ||
    pathname === "/subscription-info" ||
    pathname === "/change-password" ||
    pathname.startsWith("/menu-creation") ||
    pathname.startsWith("/buffet-menu-creation") ||
    pathname.startsWith("/menu-view") ||
    pathname.startsWith("/buffet-menu-view")
  )
    return null;

  return (
    <div
      style={{ fontFamily: "'Mulish', sans-serif" }}
      className="footer-container"
    >
      {pathname.startsWith("/restaurant") ? (
        <footer id="footer" className="footer">
          <div className="container footer-line">
            <div className="container">
              <div className="row" style={{ padding: "0px" }}>
                <hr className="footer-divider d-lg-none" />
                <div className="col-lg-5 col-md-5 cef" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  {isShowWorkingHours ? (
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          marginBottom: "10px",
                        }}
                      >
                        OPENING HOURS
                      </h4>
                      <div>
                      {branchDetail?.workingHour !== null &&
                        Array.isArray(branchDetail?.workingHour) &&
                        (() => {
                          // Group the working hours by day
                          const groupedHours = branchDetail?.workingHour.reduce((acc: any, item: any) => {
                            const dayName =
                              item.day === 1
                                ? "Monday"
                                : item.day === 2
                                ? "Tuesday"
                                : item.day === 3
                                ? "Wednesday"
                                : item.day === 4
                                ? "Thursday"
                                : item.day === 5
                                ? "Friday"
                                : item.day === 6
                                ? "Saturday"
                                : item.day === 7
                                ? "Sunday"
                                : "";

                            if (!acc[dayName]) {
                              acc[dayName] = [];
                            }

                            // Check for "closed" condition
                            const timeRange =
                              item.startTime === "00:00" && item.endTime === "00:00"
                                ? "Closed"
                                : `${formatTime(item.startTime)} - ${formatTime(item.endTime)}`;

                            acc[dayName].push(timeRange);
                            return acc;
                          }, {});

                          // Convert grouped hours to JSX
                          return Object.entries(groupedHours).map(([day, times]: any, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "1.1rem",
                                lineHeight: "1.5",
                                color: "#333",
                              }}
                            >
                              {/* Day */}
                              <div style={{ flex: "1" }}>{day}</div>

                              {/* Colon */}
                              <div style={{ marginRight: "10px" }}>:</div>

                              {/* Times */}
                              <div style={{ flex: "2", textAlign: "left" }}>
                                {times.every((time: string) => time === "Closed")
                                  ? "Closed"
                                  : times.join(", ")}
                              </div>
                            </div>
                          ));
                        })()}
                    </div>

                    </div>
                  ) : null}
                </div>
                <hr className="footer-divider d-lg-none" />
                <div className="col-lg-4 col-md-4 d-flex cef">
                  <div>
                    <h4>Contact Us</h4>
                    <p>
                      {branchDetail && branchDetail?.contactNumber && (
                        <a
                          href={`tel:${branchDetail?.contactNumber}`}
                          className="a-tag"
                          style={{ fontSize: "large" }}
                        >
                          <i className="bi bi-telephone icon" />{" "}
                          {branchDetail?.contactNumber}
                        </a>
                      )}
                    </p>
                    <p>
                      {branchDetail && branchDetail?.email && (
                        <a
                          href={`mailto:${branchDetail?.email || ""}`}
                          className="a-tag"
                          style={{ fontSize: "large" }}
                        >
                          <i className="bi bi-envelope-at icon" />{" "}
                          {branchDetail?.email}
                        </a>
                      )}
                    </p>
                    <p>
                      {branchDetail && branchDetail?.address && (
                        // <a style={{ fontSize: "large", color: "#4c4c4c" }}>
                        //   <i className="bi bi-geo-alt-fill icon" />{" "}
                        //   {branchDetails.address}
                        // </a>
                        <a
                        href={`https://www.google.com/maps?q=${branchDetail?.location?.latitude},${branchDetail?.location?.longitude}`}
                        className="a-tag"
                        target="_blank"
                        style={{ fontSize: "large", color: "#4c4c4c" }}
                      >
                        <i className="bi bi-geo-alt-fill icon" />{" "}
                        {branchDetail?.address}
                      </a>
                      )}
                    </p>
                  </div>
                </div>
                <hr className="footer-divider d-lg-none" />

                <div className="col-lg-3 col-md-3 d-flex cef">
                  <div className="cef">
                    <h4>FOLLOW US</h4>
                    <div className="social-links d-flex">
                      {/* <a href="https://x.com/menudealz/" className="twitter" target="_blank">
                    <i className="bi bi-twitter" />
                  </a> */}
                      <a
                        href="https://www.facebook.com/share/19x1UVDEiK/?mibextid=wwXIfr"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="bi bi-facebook  footer-icon" />
                      </a>
                      <a
                        href="https://www.instagram.com/menudealzz?igsh=a3Rjc3J6NWcweXUw&utm_source=qr"
                        className="instagram"
                        target="_blank"
                      >
                        <i className="bi bi-instagram  footer-icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/menu-dealz/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin  footer-icon" />
                      </a>
                      <a
                        href="https://www.youtube.com/@MenuDealz"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="bi bi-youtube  footer-icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="footer-divider d-lg-none" />
          </div>
          <div className="container">
            <div className="copyright">
              <strong>
                <span>
                  <a
                    href="https://www.innovatechsoft.com/"
                    className="a-tag"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4c4c4c", fontSize: "large" }}
                  >
                    <p>© {new Date().getFullYear()} | MenuDealz</p>
                    <p>Powered by Innovatech Software Solutions</p>
                  </a>{" "}
                </span>
              </strong>
            </div>
            <div className="footer-links">
              <div className="social-links d-flex justify-content-center">
                {/* <a
                  href="https://twitter.com/Innovatechsoft"
                  className="twitter"
                >
                  <i className="bi bi-twitter" />
                </a> */}
                <a
                  href="https://www.facebook.com/people/InnovaTech-Software-Solution/61556855141266/"
                  className="facebook"
                  target="_blank"
                >
                  <i className="bi bi-facebook footer-icon" />
                </a>
                <a
                  href="https://www.instagram.com/innovatech_software_solution/"
                  className="instagram"
                  target="_blank"
                >
                  <i className="bi bi-instagram footer-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/innovatech-software-solution/"
                  className="linkedin"
                  target="_blank"
                >
                  <i className="bi bi-linkedin footer-icon" />
                </a>
                {/* <a
                  href="https://www.tiktok.com/@innovatech_software"
                  className="linkedin"
                >
                  <i className="bi bi-tiktok" />
                </a> */}
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer
          id="footer"
          className="footer"
          // style={{ backgroundColor: "#302730" }}
        >
          <div className="container footer-line">
            <div className="row gy-3">
              <hr className="footer-divider d-lg-none" />
              <div className="col-lg-4 col-md-4 d-flex cef">
                <div>
                  <h4>CONTACT US</h4>
                  <p>
                    <a
                      href="tel:+971 50724 5494"
                      className="a-tag"
                      style={{ fontSize: "large" }}
                    >
                      <i className="bi bi-telephone icon" />
                      +971 50724 5494
                    </a>
                  </p>
                </div>
              </div>
              <hr className="footer-divider d-lg-none" />
              <div className="col-lg-4 col-md-4 d-flex cef">
                <div>
                  <h4>EMAIL US</h4>
                  <p>
                    <a
                      href="mailto:team@menudealz.com"
                      className="a-tag"
                      style={{ fontSize: "large" }}
                    >
                      <i
                        className="bi bi-envelope-at icon"
                        style={{ color: "#4c4c4c" }}
                      />
                      team@menudealz.com
                    </a>
                  </p>
                </div>
              </div>
              <hr className="footer-divider d-lg-none" />
              <div className="col-lg-4 col-md-4 d-flex cef">
                <div>
                  <h4>FOLLOW US</h4>
                  <div className="social-links d-flex">
                    {/* <a href="https://x.com/menudealz/" className="twitter" target="_blank">
                    <i className="bi bi-twitter" />
                  </a> */}
                    <a
                      href="https://www.facebook.com/share/19x1UVDEiK/?mibextid=wwXIfr"
                      className="facebook"
                      target="_blank"
                    >
                      <i className="bi bi-facebook  footer-icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/menudealzz?igsh=a3Rjc3J6NWcweXUw&utm_source=qr"
                      className="instagram"
                      target="_blank"
                    >
                      <i className="bi bi-instagram  footer-icon" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/menu-dealz/"
                      className="linkedin"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin  footer-icon" />
                    </a>
                    <a
                      href="https://www.youtube.com/@MenuDealz"
                      className="linkedin"
                      target="_blank"
                    >
                      <i className="bi bi-youtube  footer-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-divider d-lg-none" />
          <div className="container">
            <div className="copyright">
              <strong>
                <span>
                  <a
                    href="https://www.innovatechsoft.com/"
                    className="a-tag"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4c4c4c", fontSize: "large" }}
                  >
                    <p>© {new Date().getFullYear()} | MenuDealz</p>
                    <p>Powered by Innovatech Software Solutions</p>
                  </a>{" "}
                </span>
              </strong>
            </div>
            <div className="footer-links">
              <div className="social-links d-flex justify-content-center">
                {/* <a
                  href="https://twitter.com/Innovatechsoft"
                  className="twitter"
                >
                  <i className="bi bi-twitter" />
                </a> */}
                <a
                  href="https://www.facebook.com/people/InnovaTech-Software-Solution/61556855141266/"
                  className="facebook"
                  target="_blank"
                >
                  <i className="bi bi-facebook footer-icon" />
                </a>
                <a
                  href="https://www.instagram.com/innovatech_software_solution/"
                  className="instagram"
                  target="_blank"
                >
                  <i className="bi bi-instagram footer-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/innovatech-software-solution/"
                  className="linkedin"
                  target="_blank"
                >
                  <i className="bi bi-linkedin footer-icon" />
                </a>
                {/* <a
                  href="https://www.tiktok.com/@innovatech_software"
                  className="linkedin"
                >
                  <i className="bi bi-tiktok" />
                </a> */}
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}
